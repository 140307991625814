.page-full .section-header__title {
  display:none;
}

.grid-16 .title span {
    display: none;
}

.view-stores .view-content img {
    width: 100%;
}

.view-stores .view-content .store-items {
    display: flex;
    flex-wrap: nowrap;
}
.view-stores .view-header h2 {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    letter-spacing: .05em;
    margin-bottom: 24px;
    line-height: 1.3333;
    font-family:lato,Verdana,Helvetica,sans-serif;
    color: #44433f;
}
.view-stores .views-row-1, .view-stores .views-row-2, .view-stores .views-row-3, .view-stores .views-row-4, .view-stores .views-row-5, .view-stores .views-row-6 {
    width: 16.66%;
    margin-bottom: 30px;
    padding: 0 15px;
}


.view-stores .view-content.store-items .store-other .body {
    min-height: 108px;
}

.view-stores .view-content .store-other { 
  padding-bottom: 15px;
 	border-bottom: 1px solid #e5e5e5;
 	margin-bottom: 30px;
 	min-height: 160px;

}

.view-stores .view-content .store-box .views-field-field-image {
    border: 1px solid #eaeaea;
    margin-bottom: 15px;
}


.view-stores .view-content .store-box .store-other .body {
  font-size: 16px;
  font-weight: 600;
  color: #44433F;
  font-family: 'Lato',Verdana,Helvetica,sans-serif;
  margin-bottom: 20px;
}


.view-stores .view-content .store-box .store-other h3 {
  font-size: 20px;
   color: #578f2e;
  letter-spacing: -0.05em;
  line-height: 1.477;
   font-family: 'Lato',Verdana,Helvetica,sans-serif;
   margin-bottom: 10px;
}

.view-stores .view-content .store-box .store-other a {
  background: #e5e5e5;
  text-decoration: none;
  color: #7E172F;
  padding: 6px 15px;
}

.view-stores .view-content .views-row.views-row-7.views-row-odd.store-box {
  width: 50%;
  min-height: 400px;
  margin-bottom: 120px;
  margin-left: auto;
}
 .other-location-store h2 {
	text-align: center;
	font-size: 20px;
	letter-spacing: .20em;
	font-weight: bold;
	color: #44433F;
}

.view-stores .view-content .other-location-store .other-location-store-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.view-stores .view-content .other-location-store .other-location-store-inner {
    width: 46%;
    margin-right: 30px;
}

.view-stores .view-content .other-location-store .other-location-store-wrapper-inner {
    width: 50%;
}

.view-stores .view-content .other-location-store .other-location-store-detail {
    display: flex;
}

.view-stores .view-content .other-location-store .other-location-store-detail .store-other {
    width: 50%;
}

.view-stores .view-content .other-location-store .other-location-store-inner-text {
    display: flex;
    flex-wrap: wrap;
}

.view-stores .view-content .other-location-store .other-location-store-inner-text .store-box {
    width: 50%;
    padding: 0 15px;
}
.view-stores .view-content .market-store-info {
    display: flex;
    flex-wrap: nowrap;
}

.view-stores .view-content .market-store-info .store-box {
    width: 25%;
    padding: 0 15px;
}

.beauty-item-boxes {
	display: flex;
}

.beauty-item-boxes .grid-8.beauty-boxes {
    width: 50%;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    margin: 0 10px 20px 10px;
    position: relative;
    border: 1px solid #ddd;
}

.beauty-item-boxes .grid-8.beauty-boxes span {
    display: block;
    background: #ce2d7d;
    color: #fff;
    padding: 10px;
    margin-top: 10px;
    margin: 20px auto;
    max-width: 200px;
    font-weight: bold;
    font-size: 16px;
}
.beauty-item-title {
  color: #494645;
  font-weight: 500;
  letter-spacing: -0.6px;
  font-size: 30px;
}

.announcement-bar {
  cursor:pointer;
}

.announcement-bar:hover .announcement-slider__content p a{
    background:#000;
}

.toolbar {
  background: #579828;
  overflow: hidden;
}

.announcement-slider__content p {
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    font-size: 16px;
}
.announcement-slider__content p a {
    margin-left: auto;
    background: rgba(0,0,0,.5);
    padding: 4px 10px;
    text-decoration: none;
    font-weight: 700;
    pointer-events:none;
    border-radius: 4px;
  transition: all .4s ease-in;
}
.header-newsletter-cstm {
    padding: 9px 0;    
}

.header-newsletter-cstm:before {
    content: "";
    display: block;
    border-top: 1px solid rgba(0,0,0,.2)!important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: inherit;
    height: 100%;
    margin-top: -10px;
    background: rgba(0,0,0,.1);
  box-shadow:0 3px 6px -3px rgba(0,0,0,.3) inset;
}
.header-newsletter-cstm .contact-form{
  position:relative;
}
.header-newsletter-cstm .note--success {
    color: #56ad6a;
    background-color: transparent;
    border-color: #56ad6a;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 70;
    width: auto;
    max-width: inherit;
    min-width: inherit;
    display: block;
    margin-bottom: 0;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

.header-newsletter-cstm .btn {
    background: #000000;
  transition: all .3s ease;
}

.header-newsletter-cstm .btn:hover {
    color: #fff;
    background:#579828;
    border-color: #579828;
    
}

.header-newsletter-cstm .input-group-field {
    border: none;
    background: #ffffff;
}


.header-newsletter-cstm .input-group-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
}
.header-newsletter-cstm .input-group-field::-moz-placeholder { /* Firefox 19+ */
  color: #000;
}
.header-newsletter-cstm .input-group-field:-ms-input-placeholder { /* IE 10+ */
  color: #000;
}
.header-newsletter-cstm .input-group-field:-moz-placeholder { /* Firefox 18- */
  color: #000;
}

@media screen  and (max-width: 1025px) {
  .view-stores .view-content .store-items {
    display: flex;
    flex-wrap: wrap;
}
.view-stores .views-row-1, .view-stores .views-row-2, .view-stores .views-row-3, .view-stores .views-row-4, .view-stores .views-row-5, .view-stores .views-row-6 {
    width: 33%;
}
  

.view-stores .view-content .views-row.views-row-7.views-row-odd.store-box {
  width: 100%
}
.view-stores .view-content .other-location-store .other-location-store-wrapper {
  display: block;
}
.view-stores .view-content .other-location-store .other-location-store-inner {
  width: 100%;
  margin-right: 0;
}
.view-stores .view-content .other-location-store .other-location-store-wrapper-inner {
  width: 100%;
}

.view-stores .view-content .market-store-info {
  display: flex;
  flex-wrap: wrap;
}
  .view-stores .view-content .market-store-info .store-box {
    width: 50%;
  }
}
 
@media screen  and (max-width: 767px) {
.view-stores .view-header h2 {
  font-size: 24px;
}
.view-stores .view-content .store-items {
    display:block;
}
 
.view-stores .views-row-1, .view-stores .views-row-2, .view-stores .views-row-3, .view-stores .views-row-4, .view-stores .views-row-5, .view-stores .views-row-6 {
    width: 100%;
}
.view-stores .view-content .market-store-info {
  display: block;
}
.view-stores .view-content .market-store-info .store-box {
    width: 100%;
  }
.view-stores .view-content .views-row.views-row-7.views-row-odd.store-box {
  min-height: 300px;
  margin-bottom: 60px;
}
.view-stores .view-content .other-location-store .other-location-store-detail {
  display: flex;
  flex-wrap: wrap;
}
.view-stores .view-content .other-location-store .other-location-store-detail .store-other {
	width: 100%;
}
.view-stores .view-content .other-location-store .other-location-store-wrapper-inner {
	width: 100%;
}
.view-stores .view-content .other-location-store .other-location-store-inner-text {
  display: block;
}
.view-stores .view-content .other-location-store .other-location-store-inner-text .store-box {
	width: 100%;
    padding: 0; 
}
  
.beauty-item-boxes {
  display: block;
}
  
.beauty-item-boxes .grid-8.beauty-boxes {
  width: 100%;
  margin: 0 0 20px;
}
.beauty-item-title {
  font-size: 24px;
}
  
}
@media screen and (max-width:880px){
  .announcement-slider__content p {
    font-size:11px
  }
}
@media screen and (max-width:590px){
  .announcement-slider__content p {
    justify-content: center;
  }
  .announcement-slider__content p a{
    margin-left:auto;
    margin-right:auto;
    margin-top: 7px;
  }
}

/* Ingredients css start */
.int-btn.btn {
	background: #579828;
	font-weight: 400;
	font-size: 15px;
  transition: all .3s ease;
}
.int-btn.btn:hover {
	background: #65bb26;
}
.int-popup {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0,0,0,.1);
  align-items: center;
  justify-content: center;
}
.int-popup.open {
  display: flex;
}
.product-ingredients {
  background: #fff;
  width: 700px;
  padding: 49px;
  border-radius: 11px;
  box-shadow: 1px 1px 21px -9px #0000004d;
  background-image: url("https://cdn.shopify.com/s/files/1/2406/2877/files/leaf.png?v=1693487880");
  background-position: bottom 1px right -2px;
  background-size: 110px;
  background-repeat: no-repeat;
  border-left: 8px solid #93d012;
  position: relative;
}
.int-close {
  left: 0;
  top: 5px;
  position: absolute;
  right: 5px;
  left: auto;
  border: 1px solid #efefef;
  width: 36px;
  height: 36px;
  padding: 9px;
  border-radius: 40px;
  cursor: pointer;
}
.int-btn-box {
  padding: 0 0 30px;
}
.int-popup-main:empty:before {
  content: "No Details Available";
  font-size: 24px;
  text-align: center;
  display: block;
  margin: 30px;
}
/* Ingredients css end */